.App {
  text-align: center;
  background-color: gray;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.AppContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    align-content: stretch;
}

.FormContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    align-content: stretch;
}

/* mine */

.Main {
    max-width: 700px;
    margin: 0 auto;
}

.ActiveTab {
    background-color: lightgray;
}

.InactiveTab {
    font-size: 1.1em;
    text-transform: none;
}

.Button {
    background-color: lightgray;
}

.WordsTabs {
    padding: 10px 0 0 0;
}
